import React from 'react';
import { graphql } from 'gatsby';

import { Layout } from '../components/layout/layout';
import { CmsContentMap } from '../components/cms/cms-content-map';
import config from '../../config/webConfig';
const { SHOW_MODULE_NAMES } = config;

export default function PageTemplate({
  data: {
    navbar,
    page: { content, seoMetaTags, metadataSchema },
  },
}) {
  return (
    <Layout navbar={navbar} metadataSchema={metadataSchema} seoMetaTags={seoMetaTags}>
      <CmsContentMap content={content} shouldShowModuleNames={SHOW_MODULE_NAMES} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query MarketingPage($slug: String!, $category: String!) {
    navbar: datoCmsNavbar(category: { eq: $category }) {
      ... on DatoCmsNavbar {
        ...Navbar
      }
    }
    page: datoCmsPage(slug: { eq: $slug }) {
      seoMetaTags {
        tags
      }
      title
      metadataSchema
      content {
        ... on DatoCmsFeature {
          ...Feature
        }
        ... on DatoCmsDuo {
          ...Duo
        }
        ... on DatoCmsSeparator {
          ...Separator
        }
        ... on DatoCmsCallToAction {
          ...CallToAction
        }
        ... on DatoCmsCallToActionBanner {
          ...CallToActionBanner
        }
        ... on DatoCmsImageWithText {
          ...ImageWithText
        }
        ... on DatoCmsShowcaseHero {
          ...ShowcaseHero
        }
        ... on DatoCmsAnimationHero {
          ...AnimationHero
        }
        ... on DatoCmsTextContent {
          ...TextContent
        }
        ... on DatoCmsFeaturedImage {
          ...FeaturedImage
        }
        ... on DatoCmsFeaturedList {
          ...FeaturedList
        }
        ... on DatoCmsJobListing {
          ...JobListing
        }
      }
    }
  }
`;
